import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "@/assets/sass/style.css";

// swiper
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/thumbs';

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);

app.mount("#app");