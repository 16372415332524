<template>
  <div class="footer">
    <div class="bg_black">
      <div class="container-fluid">
        <div class="main_box">
          <div class="position-relative z-1">
            <RouterLink to="/">
              <img src="https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731740280414.webp" alt="footer_logo"
                class="footer_logo" loading="lazy" />
            </RouterLink>
            <div class="soical_links d-flex align-items-center">
              <a href="https://www.facebook.com/people/Hola-Prime/61565158992654" target="_blank">
                <svg class="cursor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="#ffffff"
                    d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
                </svg>
              </a>
              <a href="https://www.instagram.com/holaprime_global/" target="_blank">
                <svg class="cursor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#FFD43B"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/hola-prime/" target="_blank">
                <svg class="cursor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#FFD43B"
                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                </svg>
              </a>
              <a href="https://x.com/HolaPrimeGlobal" target="_blank">
                <svg class="cursor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="#FFD43B"
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </a>
              <a href="https://www.youtube.com/@HolaPrime_Global" target="_blank">
                <svg class="cursor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path fill="#FFD43B"
                    d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                </svg>
              </a>
              <a href="https://discord.com/invite/TJ7TcHPXBf" target="_blank">
                <svg class="cursor" xmlns="http://www.w3.org/2000/svg" height="14" width="17.5" viewBox="0 0 640 512">
                  <path fill="#FFD43B"
                    d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
                </svg>
              </a>
            </div>
            <a href="mailto:contactus@holaprime.com" class="email_link text-decoration-none">Contactus@holaprime.com</a>
          </div>
          <!-- links box -->
          <div class="links_box">
            <!-- About us links group  -->
            <div class="links_group">
              <div class="links_title">About us</div>
              <ul>
                <li>
                  <RouterLink to="/about-us"> Who we are </RouterLink>
                </li>
                <li>
                  <RouterLink to="/teams"> Our Team </RouterLink>
                </li>
                <li>
                  <RouterLink to="/contact-us"> Contact Us </RouterLink>
                </li>
                <li>
                  <RouterLink to="/careers"> Careers </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Funding Plans links group  -->
            <div class="links_group">
              <div class="links_title">Funded</div>
              <ul>
                <li>
                  <RouterLink to="/pro-challenge"> Pro Challenges </RouterLink>
                </li>
                <li>
                  <RouterLink to="/prime-challenge">Prime Challenges
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/scaling"> Scaling Plan </RouterLink>
                </li>
                <li>
                  <RouterLink to="/trading-rules"> Trading Rules </RouterLink>
                </li>
                <li>
                  <RouterLink to="/visa-card"> Visa Card </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Free Resources links group  -->
            <div class="links_group">
              <div class="links_title">Trading</div>
              <ul>
                <li>
                  <RouterLink to="/leaderboard"> Leaderboard </RouterLink>
                </li>
                <li>
                  <RouterLink to="/transparency-report">Transparency Report</RouterLink>
                </li>
                <li>
                  <RouterLink to="/trading-platforms">Platforms</RouterLink>
                </li>
                <li>
                  <RouterLink to="/trading-tools">Trading Tools</RouterLink>
                </li>
                <li>
                  <RouterLink to="/1-hour-payouts">1-Hour Payouts</RouterLink>
                </li>
                <li>
                  <RouterLink to="/competition">Competition</RouterLink>
                </li>
              </ul>
            </div>
            <!-- Academy links group  -->
            <div class="links_group order-2 order-lg-0">
              <div class="links_title">Academy</div>
              <ul>
                <li>
                  <router-link to="/prime-academy">Prime Academy</router-link>
                </li>
                <li>
                  <router-link to="/trading-coach">Trading Coaches</router-link>
                </li>
                <li>
                  <router-link to="/blogs">Blogs</router-link>
                </li>
              </ul>
            </div>
            <!-- FAQs links group  -->
            <div class="links_group order-3 order-lg-0">
              <div>
                <router-link to="/faq" class="links_title">FAQs</router-link>
              </div>
            </div>
            <!-- Affiliate links group  -->
            <div class="links_group order-1 order-lg-0">
              <div class="links_title">Affiliate</div>
              <ul>
                <li>
                  <router-link to="/affiliate-program">Partners</router-link>
                </li>
                <li>
                  <router-link to="/faq/affilate">FAQs</router-link>
                </li>
                <li>
                  <a href="https://trader.holaprime.com/en/sign-in" target="_blank">Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="bottom_box mt-5">
          <div class="mw_746 mb-0">
            <p class="fs_12">
              Risk Warning: Leveraged products such as Forex and CFD trading are
              complex instruments with a high risk of losing money. You must be
              aware of the risks and be willing to accept them to trade in the
              markets.
            </p>
            <p class="fs_12 ">
              Disclaimer: All information provided on this website is solely for
              educational purposes within a simulated trading environment, and
              should not be construed as investment or financial advice or
              recommendation. Hola Prime® does not provide services for
              residents of any country where such distribution or use would be
              contrary to local law or regulation. You must be 18 years old, or
              of legal age as determined in your country. Our services are
              designed to evaluate an individual's trading competencies, and
              outcomes depend on the individual's proficiency and adherence to
              our trading program guidelines and goals. Hola Prime® is not a
              broker and thus doesn't receive deposits from its clients.
              Liquidity providers facilitate the technical setup and price feed
              data for Hola Prime's platforms.
            </p>
            <p class="fs_12 mb-0">
              This is the only website for Hola Prime. We are not using any
              third party websites or links. Any link, outside of this website
              that claims to be ours, could be fraudulent and users are advised
              to not use it.
            </p>
          </div>
          <div class="copyright_box d-flex flex-column">
            <p class="text-start mb_20 order-2 order-lg-0">
              © 2024 Hola Prime All rights reserved.
            </p>
            <div class="copyright_links flex-wrap gap-3">
              <router-link to="/privacy-policy" class="fs_12" aria-current="page">
                <span>Privacy Policy</span>
              </router-link>
              <router-link to="/terms-conditions" class="fs_12" aria-current="page">
                <span>Terms & Conditions</span>
              </router-link>
              <router-link to="/risk-disclosure" class="fs_12" aria-current="page">
                <span>Risk Disclosure</span>
              </router-link>
              <router-link to="/anti-money-laundering-policy" class="fs_12" aria-current="page">
                <span>Anti-money laundering policy</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg_glow_light"></div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      images: [
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909132747.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909161221.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909174096.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909199045.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909214914.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909242140.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909252657.webp",
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909261030.webp",
      ],
      texts: [
        "security",
        "reliability",
        "protection",
        "results",
        "precision",
        "trust",
        "success",
        "growth",
        "honesty",
      ],
      currentIndex: 0,
      currentImage:
        "https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731909122605.webp",
      currentText: "security",
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    // this.setupAnimation();
  },
  methods: {
    changeContent() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.currentImage = this.images[this.currentIndex];
      this.currentText = this.texts[this.currentIndex];
    },
    // setupAnimation() {
    //   gsap
    //     .timeline({ repeat: -1, repeatDelay: 1.5 })
    //     .to([".slide-image", ".slide-text"], {
    //       duration: 0.5,
    //       y: -30, // Slide up
    //       opacity: 0,
    //       onComplete: this.changeContent,
    //     })
    //     .set([".slide-image", ".slide-text"], { y: 30, opacity: 0 })
    //     .to([".slide-image", ".slide-text"], {
    //       duration: 0.5,
    //       y: 0,
    //       opacity: 1,
    //     });
    // },
  },
};
</script>
